.toastr-container {
    position: fixed;
    z-index: 999999;
    pointer-events: none;
    opacity: 1;
    transition: opacity .5s ease-in;
}

.toastr-content {
    padding: 15px 15px 15px 50px;
    width: 300px;
    box-shadow: 0 0 12px #999999;
    overflow: hidden;
    border-radius: 3px;
    color: #FFFFFF;
    opacity: 0.9;
    font-size: 13px;
    line-height: 28px;
}

.toastr-info {
    background-color: rgb(75, 55, 60);
}

.toastr-bc {
    width: 100%;
    top: 80px;
    right:100px;
}

.toastr-tc > .toastr-content,
.toastr-bc > .toastr-content {
    margin-left: auto;
    /*margin-right: auto;*/
}

.toast-title {
    font-weight: 700;
}

.toast-message {
    font-weight: 400;
}

.toastr-hidden {
    opacity: 0;
    transition: opacity .5s ease-in;
}